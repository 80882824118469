import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/rfps/" replace />, index: true },
        // { path: '/rfps', element: <Navigate to="/rfps" replace />, index: true },
        { path: '/rfps/', element: <LeadPage /> },
        { path: '/rfps/:rid', element: <LeadPage /> },
        // { path: `Proposal-Builder='matching'=what-we're-working-on-now=the-team-will-be-using-this-page`, element: <LeadPage /> },
        { path: '/hire/', element: <HirePage /> },
      ],
    },
    {
      path: '/rfps-dev/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/rfps-dev/" replace />, index: true },
        { path: '/rfps-dev/', element: <LeadPageDev /> },
        { path: '/rfps-dev/:rid', element: <LeadPageDev /> }
      ],
    },
    {
      path: '/match/',
      element: <DashboardLayout />,
      children: [
        { path: '/match/', element: <MatchTalent /> }
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const LeadPage = Loadable(lazy(() => import('../production/leads')));
const LeadPageDev = Loadable(lazy(() => import('../staging/leads')));
const HirePage = Loadable(lazy(() => import('../pages/Hire/Hire')));
const MatchTalent = Loadable(lazy(() => import('../staging/match')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
